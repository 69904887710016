import { useRouter } from "../../hooks";
import { NotFound } from "../NotFound";
import { MainPage } from "../Main";

export function Router() {
  const route = useRouter();

  switch (true) {
    case /^$/.test(route.page):
      return <MainPage />

    default:
      return <NotFound />
  }
}
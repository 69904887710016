// import { useApp } from './useApp'
import { Router } from '../pages/router'
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
// import { PasswordSignIn } from '@narayana/sso-ui';

function App() {
  /*
  const { isReady, me, inProgress, error, setError, signIn } = useApp();

  if (!isReady) {
    return (
      <Backdrop open={!isReady}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (me == null) {
    return (
      <PasswordSignIn
        error={error}
        disabled={inProgress}
        onErrorChange={setError}
        onLogin={signIn}
      />
    );
  }
  */

  return (
    <Router />
  );
}

export default App;

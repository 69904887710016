import { SMSDialogListItem } from "../reducers";

export const mockedApi = {
  listDialogs: async (query: string, offset: number, limit: number):Promise<SMSDialogListItem[]> => {
    return new Promise(
      (resolve, reject)=>{
        setTimeout(
          ()=>resolve(
            [
              {
                id:'1',
                device:'mobile1',
                participant: 'mom',
                displayMessage: {
                  id: '1',
                  from: 'me',
                  to: 'mom',
                  date: '2023-07-01 12:22',
                  body: {type: 'text', text: 'hello world'},
                  status: 'delivered',
                },
                hasNew: true
              }
            ]
          ), 3000
        );
      }
    );
  },
  searchDialogs: async (query: string, offset: number, limit: number):Promise<SMSDialogListItem[]> => {
    return new Promise(
      (resolve, reject)=>{
        setTimeout(
          ()=>resolve(
            [
              {
                id:'1',
                device:'mobile1',
                participant: 'mom',
                displayMessage: {
                  id: '1',
                  from: 'me',
                  to: 'mom',
                  date: '2023-07-01 12:22',
                  body: {type: 'text', text: 'hello world'},
                  status: 'delivered',
                },
                hasNew: true
              }
            ]
          ), 3000
        );
      }
    );
  },
  deleteDialogs: async (ids: string[]):Promise<void> => {
    return new Promise(
      (resolve, reject)=>{
        setTimeout(
          ()=>resolve(
          ), 3000
        );
      }
    );
  }
};

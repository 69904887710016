import { ActiveDialog, SMSDialogListItem, SMSView } from "../reducers";
import { DialogsListAction } from "./dialogs";
import { DialogsListSelectionAction } from "./select";
import { DialogsListSearchAction } from "./search";
import { DialogsListView } from "./views";
import { Dispatch } from "@reduxjs/toolkit";
export const DialogActionsMapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addToSelection: (id: string) => dispatch({type: 'DIALOGS_LIST_SELECT_ADD', payload: id}),
    removeFromSelection: (id: string) => dispatch({type: 'DIALOGS_LIST_SELECT_REMOVE', payload: id}),
    enableSelection: () => dispatch({type: 'DIALOGS_LIST_SELECT_ENABLE', payload: null}),
    disableSelection:() => dispatch({type: 'DIALOGS_LIST_SELECT_DISABLE', payload: null}),

    delete: (ids: string[]) => dispatch({type: '*DIALOGS_LIST_DELETE_REQUESTED', payload: ids}),
    deleted: () => dispatch({type: 'DIALOGS_LIST_DELETE_SUCCEEDED', payload: null}),
    deleteFailed: (error: string) => dispatch({type: 'DIALOGS_LIST_DELETE_FAILED', payload: error}),

    list: (request: FilterProps) => dispatch({type: '*DIALOGS_LIST_FETCH_REQUESTED', payload:request}),
    listSucceeded: (dialogs: SMSDialogListItem[]) => dispatch({type: 'DIALOGS_LIST_FETCH_SUCCEEDED', payload:dialogs}),
    listFailed: (error: string) => dispatch({type:'DIALOGS_LIST_FETCH_FAILED', payload: error}),

    search: (query: FilterProps) => dispatch({type: '*DIALOGS_LIST_SEARCH_REQUESTED', payload:query}),
    searchSucceeded: (dialogs: SMSDialogListItem[]) => dispatch({type: 'DIALOGS_LIST_SEARCH_SUCCEEDED', payload:dialogs}),
    searchFailed: (error: string) => dispatch({type: 'DIALOGS_LIST_SEARCH_FAILED', payload:error}),

    openView: (view: SMSView) => dispatch({type: 'DIALOGS_LIST_OPEN_VIEW', payload:view}),
    setActiveDialog: (id: string) => dispatch({type: '*DIALOGS_LIST_SET_ACTIVE_DIALOG_REQUESTED', payload: id}),
    setActiveDialogSucceeded: (activeDialog: ActiveDialog) => dispatch({type: 'DIALOGS_LIST_SET_ACTIVE_DIALOG_SUCCEEDED', payload: activeDialog}),
    setActiveDialogFailed: (error: string) => dispatch({type: 'DIALOGS_LIST_SET_ACTIVE_DIALOG_FAILED', payload: error})
  }
}

export type DialogsAction =
  | DialogsListAction
  | DialogsListSelectionAction
  | DialogsListSearchAction
  | DialogsListView;

export type BaseAction<S, T> = {
  type: S;
  payload: T;
};

export type PaginationProps = {
  offset: number;
  limit: number;
};
export type FilterProps = PaginationProps & { query: string };

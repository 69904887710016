import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16
  }
});
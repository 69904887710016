import { Typography } from "@mui/material";
import { useRouter } from "../../hooks";

export function NotFound () {
  const route = useRouter();
  
  return (
    <div>
      <Typography variant="body1">
        Page {route.page} not found
      </Typography>
    </div>
  )
}
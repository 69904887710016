import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './useStyles';
import { connect } from 'react-redux';
import { DialogsState } from '../../state/dialogs/reducers';
import { DialogActionsMapDispatchToProps, FilterProps } from '../../state/dialogs/actions';

const DialogsSearchContainer = ({ searchQuery }: DialogsSearchProps) => {
  const styles = useStyles();

  return (
    <div className={styles.searchField}>
      <div className={styles.iconWrapper} >
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        className={styles.searchInput}
        value={searchQuery}
      />
    </div>
  );
};

export type DialogsSearchProps = {
  searchQuery?: string;
  search?: (query: FilterProps) => void;
};

const mapStateToProps = (state: DialogsState) => ({
  searchQuery: state.searchString
});

export const DialogsSearch = connect(
  mapStateToProps,
  DialogActionsMapDispatchToProps
)(DialogsSearchContainer);
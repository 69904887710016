import { DeviceView } from "./views/device";
import { DevicesView } from "./views/devices";
import { Errors } from "./errors";
import { MainView } from "./views/main";
import { NewDeviceView } from "./views/new-device";
import { useData } from "./useCalls";
import { CallsDataActions } from "./useCalls/actions";
import { CallsData } from "./useCalls/data";

export function CallsApp(props: CallsProps) {
  const { actions, ...state } = useData();

  return (
    <div>
      <Errors state={state} actions={actions} />
      <CallsAppInner state={state} actions={actions} />
    </div>
  )
}

function CallsAppInner({ state, actions }: ViewProps) {
  switch (state.view) {
    case 'device':
      return <DeviceView state={state} actions={actions}  />;

    case 'devices':
      return <DevicesView state={state} actions={actions}  />;

    case 'main':
      return <MainView state={state} actions={actions}  />;

    case 'new-device':
      return <NewDeviceView state={state} actions={actions}  />;

    default:
      return <div>Unhandled view {state.view}</div>;
  }
}

export type CallsProps = {
  open: boolean;
}

export type ViewProps = {
  state: CallsData,
  actions: CallsDataActions
};
import List from "@mui/material/List";
import { useStyles } from "./useStyles";
// import React from "react";

export const DialogsList = () => {
  // const [checked, setChecked] = React.useState([1]);

  // const handleToggle = (value: number) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  // };
  const styles = useStyles();

  return (
    <List  className={styles.dialogsListItem}>
      {[0, 1, 2, 3].map((value) => {
        // const labelId = `checkbox-list-secondary-label-${value}`;
        return (
          null
        );
      })}
    </List>
  );
}
import AddIcon from "@mui/icons-material/Add";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HomeIcon from '@mui/icons-material/Home';
import SimCardIcon from "@mui/icons-material/SimCard";
import Fab from "@mui/material/Fab";
import { ViewProps } from "../..";
import { useStyles } from "./styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

export const DevicesView = ({ state, actions }: ViewProps) => {
  const styles = useStyles();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link 
          sx={{ cursor: "pointer" }}
          underline="hover" 
          color="inherit" 
          onClick={actions.gotoMain}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Main
        </Link>
        <Link 
          underline="none" 
          color="inherit" 
          onClick={actions.gotoDevices}
        >
          <SimCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Devices
        </Link>
      </Breadcrumbs>
      <Typography variant="h4">
        Devices
      </Typography>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {state.devices.map(({ id, title, login, domain, isActive, connection }) => (
          <ListItem 
            key={id}
            sx={{ cursor: 'pointer' }} 
            onClick={() => actions.gotoDevice(id)}
          >
            <ListItemAvatar sx={{ color: connection == null ? '#eb3434' : '#25cf19' }}>
              <FiberManualRecordIcon />
            </ListItemAvatar>
            <ListItemText 
              primary={`${title}${isActive ? '' : ' [disabled]'}`} 
              secondary={`${login}@${domain}`} 
            />
          </ListItem>
        ))}
      </List>
      <Fab 
        sx={{ cursor: "pointer" }}
        className={styles.mainAction} 
        color="primary"
        onClick={actions.gotoNewDevice}
      >
        <AddIcon />
      </Fab>
    </div>
  )
};
import AppBar from "@mui/material/AppBar";
import ArtTrackIcon from '@mui/icons-material/Call';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import SmsIcon from "@mui/icons-material/Sms";
import StoreIcon from "@mui/icons-material/Store";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { CallsApp } from "../../CallsApp";

import { BlogsApp } from "../../BlogsApp";
import { useStyles } from "./styles";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import { SMSApp } from "../../SMSApp";

export function MainPage() {
  const styles = useStyles();
  const [blogsAppOpen, setBlogsAppOpen] = useState(false);
  const [navigationValue, setNavigationValue] = useState(null as number | null);
  const handleBlogsAppClose = () => {
    setBlogsAppOpen(false);
  };
  const handleClickBlogsAppOpen = () => {
    setBlogsAppOpen(true);
  };
  const handleCallsAppClose = useCallback(
    () => {
      setNavigationValue(-1);
    }, []
  );
  const handleSMSAppClose = useCallback(
    () => {
      setNavigationValue(-1);
    }, []
  );

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.row}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: "column",
                cursor: 'pointer'
              }}
              onClick={handleClickBlogsAppOpen}
            >
              <Icon sx={{ width: 32, height: 32, marginBottom: '4px' }}>
                <ArtTrackIcon sx={{ fontSize: 32 }} />
              </Icon>
              Blogs
            </div>
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={navigationValue}
          onChange={(event, newValue) => {
            setNavigationValue(newValue);
          }}
        >
          <BottomNavigationAction label="Calls" icon={<CallIcon />} />
          <BottomNavigationAction label="SMS" icon={<SmsIcon />} />
          <BottomNavigationAction label="Market" icon={<StoreIcon />} />
        </BottomNavigation>
      </Paper>
      <Dialog
        open={blogsAppOpen}
        onClose={handleBlogsAppClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {"Blogs"}
        </DialogTitle>
        <DialogContent>
          <AppBar position="fixed" sx={{ marginBottom: 2 }}>
            <Toolbar>
              <Typography sx={{ flex: 1 }}>
                Blog Application
              </Typography>
              <IconButton onClick={handleBlogsAppClose}>
                <CloseIcon color="inherit" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          <BlogsApp />
        </DialogContent>
      </Dialog>
      <Dialog
        open={navigationValue === 0}
        onClose={handleCallsAppClose}
        aria-labelledby="calls-dialog-title"
        aria-describedby="calls-dialog-description"
        fullScreen
      >
        <DialogContent>
          <DialogContentText id="calls-dialog-description">
          </DialogContentText>
          <CallsApp open={false} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={navigationValue === 1}
        onClose={handleSMSAppClose}
        aria-labelledby="calls-dialog-title"
        aria-describedby="sms-dialog-description"
        fullScreen
      >
        <DialogContent>
          <DialogContentText id="sms-dialog-description">
          </DialogContentText>
          <SMSApp open={false} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
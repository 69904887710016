import { all, call, put, takeLatest } from "redux-saga/effects";
import { type DialogsListFetchRequested, DialogsListDeleteRequested } from "../actions/dialogs";
import { mockedApi as api} from './mockedApi';
import { SMSDialogListItem } from "../reducers";
import { DialogsListSearchRequested } from "../actions/search";

export default function* rootDialogsSaga(){
  yield all([
    watchFetchDialogs(),
    watchSearchDialogs(),
    watchDeleteDialogs()
  ]);
}

function* fetchDialogs(action: DialogsListFetchRequested){
  try {
    const dialogs: SMSDialogListItem =
      yield call(
        api.listDialogs,
        action.payload.query,
        action.payload.offset,
        action.payload.limit
      );
    yield put({type: 'DIALOGS_LIST_FETCH_SUCCEEDED', payload: dialogs});
  } catch (e) {
    yield put({type:'DIALOGS_LIST_FETCH_FAILED', payload:(e as Error).message});
  }
}

function* searchDialogs(action: DialogsListSearchRequested) {
  try {
    const dialogs: SMSDialogListItem[] =
      yield call(
        api.searchDialogs,
        action.payload.query,
        action.payload.offset,
        action.payload.limit
      );
    yield put({type:'DIALOGS_LIST_SEARCH_SUCCEEDED', payload: dialogs});
  } catch (e) {
    yield put ({type: 'DIALOGS_LIST_SEARCH_FAILED', payload: (e as Error).message})
  }
}

function* deleteDialogs(action: DialogsListDeleteRequested) {
  try {
    yield call(
      api.deleteDialogs,
      action.payload
    );
    yield put({type:'DIALOGS_LIST_DELETE_SUCCEEDED', payload: null});
  } catch (e) {
    yield put ({type: 'DIALOGS_LIST_DELETE_FAILED', payload: (e as Error).message})
  }
}

export function* watchFetchDialogs() {
  yield takeLatest('*DIALOGS_LIST_FETCH_REQUESTED', fetchDialogs);
}

export function* watchSearchDialogs() {
  yield takeLatest('*DIALOGS_LIST_SEARCH_REQUESTED', searchDialogs);
}

export function* watchDeleteDialogs() {
  yield takeLatest('*DIALOGS_LIST_DELETE_REQUESTED', deleteDialogs);
}
import { ReducerAction } from "./actions";
import { CallsData } from "./data";

export const reducer = (state: CallsData, action: ReducerAction): CallsData => {
  switch (action.type) {
    case 'ReducerActionDevicesSet':
      return recalcActiveDevice({
        ...state,
        devices: action.devices
      });

    case 'ReducerActionDeviceAdd':
      return {
        ...state,
        devices: [ ...state.devices, action.device ]
      };

    case 'ReducerActionDeviceSetConnection':
      return {
        ...state,
        activeDeviceId: state.activeDeviceId != null || action.connection == null
          ? state.activeDeviceId
          : action.deviceId,
        devices: state.devices.map((device) => device.id === action.deviceId 
          ? { ...device, connection: action.connection }
          : device
        )
      };

    case 'ReducerActionDeviceRemove':
      return recalcActiveDevice({
        ...state,
        devices: state.devices.filter(({ id }) => id !== action.id)
      });

    case 'ReducerActionDeviceUpdate':
      return {
        ...state,
        devices: state.devices.map((device) => device.id === action.device.id 
          ? { ...action.device, connection: device.connection } 
          : device
        )
      };

    case 'ReducerActionSetActiveDeviceId':
      return {
        ...state,
        activeDeviceId: action.id
      };

    case 'ReducerActionGotoMain':
      return {
        ...state,
        view: 'main'
      };

    case 'ReducerActionGotoDevices':
      return {
        ...state,
        view: 'devices'
      };

    case 'ReducerActionGotoDevice':
      return {
        ...state,
        view: 'device',
        viewDeviceId: action.id
      };

    case 'ReducerActionGotoNewDevice':
      return {
        ...state,
        view: 'new-device'
      };

    case 'ReducerActionSetInput':
      return {
        ...state,
        input: action.input
      };

    case 'ReducerActionErrorPush':
      return {
        ...state,
        errors: [ ...state.errors, action.error ]
      };
    
    case 'ReducerActionErrorsClear':
      return {
        ...state,
        errors: []
      };

    case 'ReducerActionErrorRemove':
      return {
        ...state,
        errors: state.errors.filter((_, index) => index !== action.index)
      };

    default:
      throw new Error(`Unhandled action.type ${JSON.stringify(action)}`)
  }
};

const recalcActiveDevice = (state: CallsData): CallsData => {
  state.activeDeviceId = state.devices.findIndex((device) => device.id === state.activeDeviceId) > -1 
    ? state.activeDeviceId
    : (state.devices[0])?.id;

  return state;
}

export type ReducerFn = (state: CallsData, action: ReducerAction) => CallsData;
import SimCardIcon from "@mui/icons-material/SimCard";
import FormControl from "@mui/material/FormControl";
import { ViewProps } from "../../..";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "./styles";

export const MainDevices = ({ state, actions }: ViewProps) => {
  const styles = useStyles();

  const handleActiveDeviceChange = (event: SelectChangeEvent) => {
    actions.activeDeviceSet(event.target.value as string);
  };

  return (
    <div className={styles.root}>
      <FormControl fullWidth>
        <InputLabel id="device-select-label">Device</InputLabel>
        <Select
          labelId="device-select-label"
          value={state.activeDeviceId}
          label="Device"
          onChange={handleActiveDeviceChange}
        >
          {state
            .devices
            .filter((x) => x.connection != null)
            .map(({ id, title, login, domain }) => (
              <MenuItem key={id} value={id}>
                {`${title} (${login}@${domain})`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <IconButton 
        size="large" 
        color="primary"
        sx={{ marginLeft: 1 }}
        onClick={actions.gotoDevices}
      >
        <SimCardIcon />
      </IconButton>
    </div>
  )
};
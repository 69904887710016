import DeleteIcon from '@mui/icons-material/Delete';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { v4 as uuidV4 } from "uuid";
import { ViewProps } from "..";
import { CallsDataDevice } from "../useCalls/data";
import { useData } from "./useData";
import { useStyles } from "./styles";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export function DeviceForm ({ state, actions, device }: DeviceFormProps) {
  const styles = useStyles();
  const [ menuEl, setMenuElement ] = useState<HTMLElement | undefined>();
  const {
    id,
    title,
    wss,
    domain,
    login,
    password,
    isActive,
    passwordVisible,
    deviceActions
  } = useData(device);
  const formTitle = useMemo(
    () => id == null ? 'New Device' : state.devices.find(x => x.id === id)?.title || '[Unknown device]',
    [ state.devices, id ]
  );

  const handleSave = React.useCallback(
    () => {
      try {
        const data = { title, wss, domain, login, password, isActive };
        if (id == null) {
          return actions.deviceAdd({ ...data, id: uuidV4() });
        }

        return actions.deviceUpdate({ ...data, id, connection: device.connection });
      } finally {
        actions.gotoDevices();
      }
    },
    [ actions, id, wss, title, domain, login, password, isActive, device.connection ]
  );

  const handleDelete = React.useCallback(
    () => {
      actions.deviceRemove(id!);
      actions.gotoDevices();
    },
    [ actions, id ]
  );

  const showMenu = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => setMenuElement(e.currentTarget), 
    []
  );
  const hideMenu = React.useCallback(
    () => setMenuElement(undefined), 
    []
  );

  return (
    <>
      <Toolbar>
        <Typography variant="h4">
          {formTitle}{isActive ? '' : ' [disabled]'}
        </Typography>
        <div style={{ flex: 1 }} />
        {id == null 
          ? null
          : (
            <IconButton onClick={menuEl == null ? showMenu : hideMenu}>
              <ExpandCircleDownIcon 
                fontSize='large' 
                className={styles.menuButton}
                sx={menuEl != null ? { transform: 'rotate(180deg)' } : null}
              />
            </IconButton>
          )
        }
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={menuEl!}
          open={menuEl != null}
          onClose={hideMenu}
        >
          <MenuItem>
            <FormControlLabel 
              control={<Switch checked={isActive} onChange={deviceActions.setIsActive} />} 
              label="Enable" 
            />
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <DeleteIcon />
            Delete
          </MenuItem>
        </Menu>
      </Toolbar>
      <div className={styles.root}>
        <TextField 
          fullWidth
          label="Title"
          value={title}
          onChange={deviceActions.setTitle}
        />
        <TextField 
          fullWidth
          label="WSS"
          value={wss}
          onChange={deviceActions.setWSS}
        />
        <TextField 
          fullWidth
          label="Domain"
          value={domain}
          onChange={deviceActions.setDomain}
        />
        <TextField 
          fullWidth
          label="Login"
          value={login}
          onChange={deviceActions.setLogin}
        />
        <TextField 
          fullWidth
          label="Password"
          type={passwordVisible ? 'text' : 'password'}
          value={password}
          InputProps={{
            endAdornment: (
              <IconButton onClick={deviceActions.togglePasswordVisible}>
                {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            )
          }}
          onChange={deviceActions.setPassword}
        />
        <div className={styles.actions}>
          <Button color="secondary" onClick={actions.gotoDevices}>
            CANCEL
          </Button>
          <Button color="primary" onClick={handleSave}>
            {id == null ? 'CREATE' : 'UPDATE'}
          </Button>
        </div>
      </div>
    </>
  );
}

export type DeviceFormProps = ViewProps & { 
  device: Omit<CallsDataDevice, 'id'> & { id?: string | null | undefined } 
};
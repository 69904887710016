import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    display: 'flex', 
    flexDirection: 'column',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '600px',
    width: '100%',
    margin: 'auto'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 96,
    '& > div': {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
});
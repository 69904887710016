import { ReducerAction } from "./actions";
import { CallsData, CallsDataDevice, EMPTY_CALLS_DATA } from "./data";
import { ReducerFn } from "./reducer";

export const getState = (): CallsData => {
  try {
    const { devices, activeDeviceId  } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '') as CallsDataStorage;
    return { 
      ...EMPTY_CALLS_DATA, 
      devices: devices || [], 
      activeDeviceId 
    }
  } catch (e) {
    return EMPTY_CALLS_DATA;
  }
};

export const reducerWrapper = (reducer: ReducerFn): ReducerFn => 
  (state: CallsData, action: ReducerAction) => {
    const newState = reducer(state, action);

    const toSave: CallsDataStorage = {
      devices: newState.devices.map(({ connection, ...device }) => device),
      activeDeviceId: newState.activeDeviceId
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(toSave));

    return newState;
  };

export type CallsDataStorage = {
  devices: CallsDataStorageDevice[],
  activeDeviceId: CallsData['activeDeviceId']
};

export type CallsDataStorageDevice = Omit<CallsDataDevice, 'connection'>;

const LOCAL_STORAGE_KEY = "CALLS_DATA";
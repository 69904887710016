import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";
import createSagaMiddleware from 'redux-saga';
import { DialogsAction } from './state/dialogs/actions';
import { DialogsState, dialogsRootReducer } from "./state/dialogs/reducers/";
import rootDialogsSaga from './state/dialogs/sagas';
import DialogsAppBar from './components/appbar';
import { DialogsList } from './components/list';

export const SMSApp = (props: SMSAppProps) => {

  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore<
    DialogsState,
    DialogsAction
  >(
    {
      reducer: dialogsRootReducer,
      middleware: (getDefaultMiddleware) => [ sagaMiddleware ],
    }
  );
  sagaMiddleware.run(rootDialogsSaga);

  return (
    <Provider store={store}>
      <>
        <DialogsAppBar />
        <DialogsList />
      </>
    </Provider>
  );
};


export type SMSAppProps = {
  open: boolean;
};
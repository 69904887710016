import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    gap: 8
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    gap: 8
  }
});
// import { Reducer } from "react";
import type { Reducer } from "@reduxjs/toolkit";
import { DialogsAction } from "../actions";
import { INIT_STATE, DialogsState } from ".";

export const dialogsSearchReducer: Reducer<DialogsState, DialogsAction> = (state, action): DialogsState => {
  switch (action.type) {
    case 'DIALOGS_LIST_SEARCH_VALUE_CHANGED': {
      return {...state!, searchString: action.payload}
    }

    case '*DIALOGS_LIST_SEARCH_REQUESTED': {
      return {...state!, inProgress: true};
    }

    case 'DIALOGS_LIST_SEARCH_SUCCEEDED': {
      return {...state!, dialogs: action.payload, inProgress: false};
    }

    case 'DIALOGS_LIST_SEARCH_FAILED': {
      return {...state!, error: action.payload, inProgress: false};
    }

    default:
      return state! || INIT_STATE;
  }
};
import { useReducer } from "react";
import { DeviceFormProps } from ".";
import { Actions, reducer } from "./reducer";

export const useData = ({ id, title, wss, domain, login, password, isActive }: DeviceFormProps['device']) => {
  const [ state, handler ] = useReducer(
    reducer, 
    { id, title, wss, domain, login, password, isActive, passwordVisible: false }
  );
  const deviceActions = new Actions(handler);

  return { ...state, deviceActions };
};
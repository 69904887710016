import React from "react";
import CallIcon from "@mui/icons-material/Call";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { ViewProps } from "../..";
import { MainDevices } from "./devices";
import { useStyles } from "./styles";

export const MainView = ({ 
  state, 
  actions
}: ViewProps) => {
  const styles = useStyles();

  const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    actions.inputSet(e.target.value)
  }, [ actions ]);
  const handleCall = React.useCallback(
    () => {
      const activeDevice = state.devices.find((x) => x.id === state.activeDeviceId);
      if (activeDevice == null) {
        actions.errorPush({ error: `Device with id "${state.activeDeviceId}" not found` });
        return;
      }

      actions.call(activeDevice, state.input);
    },
    [ actions, state.activeDeviceId, state.devices, state.input ]
  );

  const buttonsHandlers = BUTTONS.map((row) => row.map(key => () => actions.inputSet(state.input + key)));

  return (
    <div>
      <MainDevices state={state} actions={actions} />
      <TextField
        label="Phone number"
        value={state.input}
        autoFocus
        fullWidth
        onChange={handleInputChange}
      />
      <div className={styles.buttons}>
        {BUTTONS.map((keys, rowIndex) => (
          <div className={styles.buttonsRow} key={rowIndex}>
            {keys.map((key, index) => (
              <IconButton 
                key={key} 
                size="large"
                onClick={buttonsHandlers[rowIndex][index]}
              >
                <Avatar sx={{ width: 56, height: 56 }}>
                  {key}
                </Avatar>
              </IconButton>
            ))}
          </div>
        ))}
        <div>
        <IconButton 
          size="large"
          color="primary"
          onClick={handleCall}
        >
          <Avatar sx={{ width: 56, height: 56, bgcolor: 'primary.main' }}>
            <CallIcon />
          </Avatar>
        </IconButton>
        </div>
      </div>
    </div>
  )
};

const BUTTONS = [
  [ '1', '2', '3' ],
  [ '4', '5', '6' ],
  [ '7', '8', '9' ],
  [ '*', '0', '#' ],
];
export function BlogsApp(props: BlogsAppProps) {
  return (
    <>
      <h1>
        In progress
      </h1>
    </>
  );
}
export type BlogsAppProps = {
}
// import { Reducer } from "react";
import type { Reducer } from "@reduxjs/toolkit";
import { DialogsAction } from "../actions";
import { INIT_STATE, SMSMessage, DialogsState } from ".";

export const dialogsViewsReducer: Reducer<DialogsState, DialogsAction> = (state, action): DialogsState => {
  switch (action.type) {
    case 'DIALOGS_LIST_OPEN_VIEW': {
      return { ...state!, view: action.payload };
    }

    case '*DIALOGS_LIST_SET_ACTIVE_DIALOG_REQUESTED': {
      return {
        ...state!,
        activeDialog: {
          id: action.payload,
          messages: [] as SMSMessage[],
        },
        inProgress: true
      };
    }

    case 'DIALOGS_LIST_SET_ACTIVE_DIALOG_SUCCEEDED': {
      return {
        ...state!,
        activeDialog: {
          id: action.payload.id,
          messages: action.payload.messages,
        },
        inProgress: false
      };
    }

    case 'DIALOGS_LIST_SET_ACTIVE_DIALOG_FAILED': {
      return {
        ...state!,
        error: action.payload,
        inProgress: false
      };
    }
    default:
      return state || INIT_STATE;
  }
};
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { ViewProps } from "..";
import { useStyles } from "./styles";
import { CallsDataDevice } from "../useCalls/data";
import AlertTitle from "@mui/material/AlertTitle";

export function Errors ({ state, actions }: ViewProps) {
  const styles = useStyles();

  const devicesById = React.useMemo(
    () => state.devices.reduce((acc, device) => {
      acc[device.id] = device;

      return acc
    }, {} as Record<string, CallsDataDevice>), 
    [ state.devices ]
  );

  return (
    <div className={styles.root}>
      {state.errors.map((error, index) => (
        <Alert 
          key={index} 
          severity="error"
          action={(
            <IconButton onClick={() => actions.errorRemove(index)}>
              <CloseIcon />
            </IconButton>
          )}
        >
          {devicesById[error.deviceId!] == null ? '' : <AlertTitle>{devicesById[error.deviceId!]!.title}</AlertTitle>}
          {error.error}
        </Alert>
      ))}
    </div>
  );
}
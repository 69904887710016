import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import SimCardIcon from "@mui/icons-material/SimCard";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { ViewProps } from "../..";
import Alert from '@mui/material/Alert';
import { DeviceForm } from '../../device-form';

export const DeviceView = ({ state, actions }: ViewProps) => {
  const device = React.useMemo(
    () => (state.devices.find(x => x.id === state.viewDeviceId)),
    [ state.viewDeviceId, state.devices ]
  )

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link 
          sx={{ cursor: "pointer" }}
          underline="hover" 
          color="inherit" 
          onClick={actions.gotoMain}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Main
        </Link>
        <Link 
          sx={{ cursor: "pointer" }} 
          underline="hover" 
          color="inherit" 
          onClick={actions.gotoDevices}
        >
          <SimCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Devices
        </Link>
        <Link 
          underline="none" 
          color="inherit" 
        >
          <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {device?.title || '[Unknown device]'}
        </Link>
      </Breadcrumbs>
      {device == null 
        ? (<Alert severity='error'>Device with id "{state.viewDeviceId}"" not found</Alert>)
        : (<DeviceForm state={state} actions={actions} device={device} />)}
    </div>
  )
};
import JsSIP, { UA } from "jssip";
import { CallsDataDevice, CallsDataError } from "./data";

export class CallsDataActions {
  constructor (
    private handler: (action: ReducerAction) => void
  ) { }

  devicesSet = (devices: CallsDataDevice[]) => {
    this.handler({ type: 'ReducerActionDevicesSet', devices })
  }

  deviceAdd = (device: CallsDataDevice) => {
    this.handler({ type: 'ReducerActionDeviceAdd', device })
  }

  deviceRemove = (id: string) => {
    this.handler({ type: 'ReducerActionDeviceRemove', id })
  }

  deviceUpdate = (device: CallsDataDevice) => {
    this.handler({ type: 'ReducerActionDeviceUpdate', device });

    this[device.isActive ? 'connect' : 'disconnect'](device);
  }

  activeDeviceSet = (id: string) => {
    this.handler({ type: 'ReducerActionSetActiveDeviceId', id })
  }

  gotoMain = () => {
    this.handler({ type: 'ReducerActionGotoMain' })
  }

  gotoDevices = () => {
    this.handler({ type: 'ReducerActionGotoDevices' })
  }

  gotoDevice = (id: string) => {
    this.handler({ type: 'ReducerActionGotoDevice', id })
  }

  gotoNewDevice = () => {
    this.handler({ type: 'ReducerActionGotoNewDevice' })
  }

  inputSet = (input: string) => {
    this.handler({ type: 'ReducerActionSetInput', input })
  }

  errorPush = (error: CallsDataError) => {
    this.handler({ type: 'ReducerActionErrorPush', error })
  }

  errorsClear = (device: string | undefined) => {
    this.handler({ type: 'ReducerActionErrorsClear', device })
  }

  errorRemove = (index: number) => {
    this.handler({ type: 'ReducerActionErrorRemove', index })
  }

  call = (device: CallsDataDevice, input: string) => {
    if (device.connection == null) {
      this.handler({ type: 'ReducerActionErrorPush', error: { deviceId: device.id, error: 'Device offline' } });
      return;
    }
    /*
    const createHandler = (handlerName: string) =>
      (...args: unknown[]) => console.log(handlerName, ...args);
    */
    /*
      const call = device.connection.call(
      `sip:${input}`,
      {
        eventHandlers: {
          'peerconnection': createHandler('peerconnection'),
          'connecting': createHandler('connecting'),
          'sending': createHandler('sending'),
          'progress': createHandler('progress'),
          'accepted': createHandler('accepted'),
          'confirmed': createHandler('confirmed'),
          'ended': createHandler('ended'),
          'failed': createHandler('failed'),
          'newDTMF': createHandler('newDTMF'),
          'newInfo': createHandler('newInfo'),
          'hold': createHandler('hold'),
          'unhold': createHandler('unhold'),
          'muted': createHandler('muted'),
          'unmuted': createHandler('unmuted'),
          'reinvite': createHandler('reinvite'),
          'update': createHandler('update'),
          'refer': createHandler('refer'),
          'replaces': createHandler('replaces'),
          'sdp': createHandler('sdp'),
          'icecandidate': createHandler('icecandidate'),
          'getusermediafailed': createHandler('getusermediafailed'),
          'peerconnection:createofferfailed': createHandler('peerconnection:createofferfailed'),
          'peerconnection:createanswerfailed': createHandler('peerconnection:createanswerfailed'),
          'peerconnection:setlocaldescriptionfailed': createHandler('peerconnection:setlocaldescriptionfailed'),
          'peerconnection:setremotedescriptionfailed': createHandler('peerconnection:setremotedescriptionfailed'),
        },
        mediaConstraints: { 'audio': true, 'video': false }
      }
    );
    */
    // this.handler({ type: 'ReducerActionErrorPush', error: { error: 'Calls not implemented' } })
  }

  init = (devices: CallsDataDevice[]) => {
    for (const device of devices) {
      if (!device.isActive) {
        continue;
      }

      this.connect(device);
    }
  }

  connect = (device: CallsDataDevice) => {
    if (device.connection != null) {
      return;
    }

    var socket = new JsSIP.WebSocketInterface(device.wss);
    var configuration = {
      sockets  : [ socket ],
      uri      : `sip:${device.login}@${device.domain}`,
      password : device.password
    };

    var ua = new JsSIP.UA(configuration);
    ua.start();

    ua.on('disconnected', () => this.setDeviceConnection(device.id, undefined));

    ua.on('registrationFailed', () => {
      this.errorPush({ deviceId: device.id, error: 'Registration Failed' });
      this.setDeviceConnection(device.id, undefined);
    });
    ua.on('registered', () => this.setDeviceConnection(device.id, ua));
  }

  disconnect = (device: CallsDataDevice) => {
    if (device.connection == null) {
      return;
    }

    device.connection.removeAllListeners();
    device.connection.stop();
    this.setDeviceConnection(device.id, undefined);
  }

  private setDeviceConnection (deviceId: string, connection: UA | undefined) {
    this.handler({ type: 'ReducerActionDeviceSetConnection', deviceId, connection });
  }
}

export type ReducerAction =
  ReducerActionDevicesSet
  | ReducerActionDeviceAdd
  | ReducerActionDeviceSetConnection
  | ReducerActionDeviceRemove
  | ReducerActionDeviceUpdate
  | ReducerActionSetActiveDeviceId
  | ReducerActionGotoMain
  | ReducerActionGotoDevices
  | ReducerActionGotoDevice
  | ReducerActionGotoNewDevice
  | ReducerActionSetInput
  | ReducerActionErrorPush
  | ReducerActionErrorsClear
  | ReducerActionErrorRemove;

export type ReducerActionDevicesSet = {
  type: 'ReducerActionDevicesSet',
  devices: CallsDataDevice[]
};

export type ReducerActionDeviceAdd = {
  type: 'ReducerActionDeviceAdd',
  device: CallsDataDevice
};

export type ReducerActionDeviceSetConnection = {
  type: 'ReducerActionDeviceSetConnection',
  deviceId: string,
  connection: CallsDataDevice['connection']
};

export type ReducerActionDeviceRemove = {
  type: 'ReducerActionDeviceRemove',
  id: string
};

export type ReducerActionDeviceUpdate = {
  type: 'ReducerActionDeviceUpdate',
  device: CallsDataDevice
};

export type ReducerActionSetActiveDeviceId = {
  type: 'ReducerActionSetActiveDeviceId',
  id: string
};

export type ReducerActionGotoMain = {
  type: 'ReducerActionGotoMain',
};

export type ReducerActionGotoDevices = {
  type: 'ReducerActionGotoDevices',
};

export type ReducerActionGotoDevice = {
  type: 'ReducerActionGotoDevice',
  id: string
};

export type ReducerActionGotoNewDevice = {
  type: 'ReducerActionGotoNewDevice',
};

export type ReducerActionSetInput = {
  type: 'ReducerActionSetInput',
  input: string
};

export type ReducerActionErrorPush = {
  type: 'ReducerActionErrorPush',
  error: CallsDataError
};

export type ReducerActionErrorsClear = {
  type: 'ReducerActionErrorsClear',
  device: string | undefined
};

export type ReducerActionErrorRemove = {
  type: 'ReducerActionErrorRemove',
  index: number
};
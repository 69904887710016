import React from "react";
import { ApiError } from "@narayana/api";
import debounce from "lodash/debounce";

export function useRequest<R, A, E = ApiError>(
  request: (arg: A) => Promise<R>,
  options: RequestOptions = {}
): UseRequestResult<R, A, E> {
  const [ { result, id, error }, dispatch ] = React.useReducer(
    reducer, 
    INIT_STATE as unknown as State<R, E>
  );
  
  const executeFn = (arg: A): void => {
    const id = Date.now();
    dispatch({ type: 'EXECUTE', id });
    
    (async () => {
      try {
        dispatch({ type: 'THEN', result: await request(arg), id });
      } catch (error) {
        dispatch({ type: 'CATCH', error, id });
      }
    })();
  };

  const executeRef = React.useRef<(a: A) => void>();
  executeRef.current = executeRef.current || (options.debounce ? debounce(executeFn, 300): executeFn);

  return React.useMemo(() => ({
    result: result as R, 
    inProgress: id != null, 
    error: error as E,
    execute: executeRef.current!,
    isError: () => error != null
  }), [ result, id, error ]);
} 

const reducer = <T, E>(state: State<T, E>, action: Action<T, E>): State<T, E> => {
  switch (action.type) {
    case 'EXECUTE':
      return { ...state, id: action.id, error: null };

    case 'THEN':
      return state.id !== action.id
        ? { ...state }
        : { ...state, result: action.result, id: null, error: null };

    case 'CATCH':
      return state.id !== action.id
        ? { ...state }
        : { ...state, result: null, id: null, error: action.error };

    default:
      throw new Error(`Unhandled action:${JSON.stringify(action)}`)
  }
}

type State<T = void, E = ApiError> = {
  result: T | null,
  id: number | null,
  error?: E | undefined | null,
};
type Action<T, E = ApiError> = { type: 'EXECUTE', id: number }
  | { type: 'THEN', result: T, id: number }
  | { type: 'CATCH', error: E, id: number };

const INIT_STATE: State = {
  result: null,
  id: null,
  error: null
};

export type UseRequestResult<T, A, E = ApiError> = {
  result: T | null, 
  inProgress: boolean, 
  error: E,
  execute: (a: A) => void,
  isError: () => boolean
}

export type RequestOptions = {
  debounce?: boolean,
};
import React from "react";
import { reducer } from "./reducer";
import { CallsDataActions } from "./actions";
import { getState, reducerWrapper } from "./localStorage";

export const useData = () => {
  const [ state, handler ] = React.useReducer(reducerWrapper(reducer), getState());
  const actions = React.useMemo(
    () => new CallsDataActions(handler), 
    [ handler ]
  );

  // eslint-disable-next-line
  React.useEffect(() => actions.init(state.devices), []);

  return { ...state, actions };
};
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SetTitle':
      return { ...state, title: action.title };

    case 'SetWSS':
      return { ...state, wss: action.wss };

    case 'SetDomain':
      return { ...state, domain: action.domain };

    case 'SetLogin':
      return { ...state, login: action.login };

    case 'SetPassword':
      return { ...state, password: action.password };

    case 'SetIsActive':
      return { ...state, isActive: action.isActive };

    case 'TogglePasswordVisible':
      return { ...state, passwordVisible: !state.passwordVisible };

    default:
      throw new Error(`DeviceForm: Unhandled action type: ${JSON.stringify(action)}`)
  }
};

export type ReducerHander = (action: Action) => void

export class Actions {
  constructor (
    private handler: ReducerHander
  ) { }

  setTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.handler({ type: 'SetTitle', title: e.target.value });
  }

  setWSS = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.handler({ type: 'SetWSS', wss: e.target.value });
  }

  setDomain = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.handler({ type: 'SetDomain', domain: e.target.value });
  }

  setLogin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.handler({ type: 'SetLogin', login: e.target.value });
  }

  setPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.handler({ type: 'SetPassword', password: e.target.value });
  }

  togglePasswordVisible = () => {
    this.handler({ type: 'TogglePasswordVisible' });
  }

  setIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.handler({ type: 'SetIsActive', isActive: event.target.checked });
  }
}

export type State = {
  id: string | null | undefined,
  title: string,
  wss: string,
  domain: string,
  login: string,
  password: string,
  isActive: boolean,
  passwordVisible: boolean,
};

export type Action =
  ActionSetTitle
  | ActionSetWSS
  | ActionSetDomain
  | ActionSetLogin
  | ActionSetPassword
  | ActionSetIsActive
  | ActionTogglePasswordVisible;

export type ActionSetTitle = {
  type: 'SetTitle',
  title: string
};

export type ActionSetWSS = {
  type: 'SetWSS',
  wss: string
};

export type ActionSetDomain = {
  type: 'SetDomain',
  domain: string
};

export type ActionSetLogin = {
  type: 'SetLogin',
  login: string
};

export type ActionSetPassword = {
  type: 'SetPassword',
  password: string
};

export type ActionSetIsActive = {
  type: 'SetIsActive',
  isActive: boolean
};

export type ActionTogglePasswordVisible = {
  type: 'TogglePasswordVisible'
};
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    right: 8,
    top: 8,
    zIndex: 1000,
    '& > div': {
      marginBottom: 8
    }
  }
});
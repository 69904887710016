import AddBoxIcon from '@mui/icons-material/AddBox';
import HomeIcon from '@mui/icons-material/Home';
import SimCardIcon from "@mui/icons-material/SimCard";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { ViewProps } from "../..";
import { DeviceForm, DeviceFormProps } from '../../device-form';

export const NewDeviceView = ({ state, actions }: ViewProps) => {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link 
          sx={{ cursor: "pointer" }}
          underline="hover" 
          color="inherit" 
          onClick={actions.gotoMain}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Main
        </Link>
        <Link 
          sx={{ cursor: "pointer" }}
          underline="hover" 
          color="inherit" 
          onClick={actions.gotoDevices}
        >
          <SimCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Devices
        </Link>
        <Link 
          underline="none" 
          color="inherit" 
        >
          <AddBoxIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          New device
        </Link>
      </Breadcrumbs>
      <DeviceForm 
        state={state}
        actions={actions}
        device={DEVICE}
      />
    </div>
  )
};

const DEVICE: DeviceFormProps['device'] = {
  title: 'New Device',
  wss: 'wss://fs.yoursip.dev:7443',
  domain: '185.235.143.131',
  login: '74951200000',
  password: 'lfk11kos',
  isActive: true
}; 
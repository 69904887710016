import type { Reducer } from "@reduxjs/toolkit";
import { DialogsAction } from "../actions";
import { INIT_STATE, type DialogsState } from ".";

export const dialogsListReducer: Reducer<DialogsState, DialogsAction> = (state, action) => {
  switch (action.type) {
    case '*DIALOGS_LIST_FETCH_REQUESTED': {
      return { ...state!, inProgress: true };
    }

    case 'DIALOGS_LIST_FETCH_SUCCEEDED': {
      return {...state!, dialogs: action.payload, inProgress: false};
    }

    case 'DIALOGS_LIST_FETCH_FAILED': {
      return {...state!, error: action.payload, inProgress: false};
    }

    case '*DIALOGS_LIST_DELETE_REQUESTED': {
      return {...state!, inProgress: true};
    }

    case 'DIALOGS_LIST_DELETE_SUCCEEDED': {
      return {...state!, inProgress: false};
    }

    case 'DIALOGS_LIST_DELETE_FAILED': {
      return {...state!, error: action.payload, inProgress: false};
    }

    default:
      return state || INIT_STATE;
  }
};
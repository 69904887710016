import AppBar from "@mui/material/AppBar"
import Container from "@mui/material/Container"
import Toolbar from "@mui/material/Toolbar"
import SmsIcon from '@mui/icons-material/Sms';
import Typography from "@mui/material/Typography";
import { DialogsSearch } from "../search";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from '@mui/icons-material/Mail';
import { useStyles } from "./useStyles";
import { connect } from "react-redux";
import { DialogsState } from "../../state/dialogs/reducers";
import { DialogActionsMapDispatchToProps, FilterProps } from "../../state/dialogs/actions";

const DialogsAppBarContainer = ({notificationCount}: DialogsAppBarProps) => {
  const styles = useStyles();
  const notificationText = `${notificationCount} new messages`;

  return (
    <>
      <AppBar className={styles.appBar}>
        <Container>
          <Toolbar disableGutters>
            <SmsIcon className={styles.toolBarIcon} />
            <Typography
              variant="h6"
              noWrap
              className={styles.appBarHeader}
            >
              Messages
            </Typography>
            <DialogsSearch />
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <IconButton size="large" aria-label={notificationText} color="inherit">
                <Badge badgeContent={notificationCount} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={styles.appBarBottomSpacer} />
    </>
  );
}

export type DialogsAppBarProps = {
  notificationCount: number;
  onSearchQueryChange?: (query: string) => void;
  search?: (query: FilterProps) => void;
};

const mapStateToProps = (state:DialogsState) => ({
  notificationCount: state.dialogs.filter(({hasNew})=>(hasNew === true)).length,
});

export default connect(
  mapStateToProps,
  DialogActionsMapDispatchToProps
)(DialogsAppBarContainer);
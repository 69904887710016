import { dialogsListReducer } from "./dialogs";
import { dialogsSearchReducer } from "./search";
import { dialogsSelectReducer } from "./select";
import { dialogsViewsReducer } from "./views";
import { DialogsAction } from "../actions";

const dialogsListReducers = [
  dialogsListReducer,
  dialogsSearchReducer,
  dialogsSelectReducer,
  dialogsViewsReducer
];
export const dialogsRootReducer = (state: DialogsState | undefined, action: DialogsAction): DialogsState => {
  return dialogsListReducers.reduce((state, reducer)=>{
    return reducer(state, action);
  }, INIT_STATE);
}

// export const DIALOGS_REDUCER = {
//   dialogs: () => {
//     return;
//   }
// };

export type SMSDialogListItem = {
  id: string;
  device: string;
  participant: string;
  displayMessage: SMSMessage;
  hasNew: boolean;
};

export type SMSMessage = {
  id: string;
  from: string;
  to: string;
  date: string;
  body: SMSMessageBody;
  status: SMSStatus;
};

export type SMSMessageBody = {
  type: "text";
  text?: string;
  attachment?: Record<string, any>;
};

export type SMSStatus = "error" | "delivered" | "draft" | "sent";

export type ActiveDialog = {
  id: string;
  messages: SMSMessage[];
};

export type SMSView = "SMSDialogsList" | "SMSDialog" | "SMSNewDialog";

export const INIT_STATE: DialogsState = {
  view: "SMSDialogsList",
  dialogs: [],
  selectedDialogsParticipants: null,
  searchString: "",
  devicesFilter: [],
  devices: [],
  activeDialog: null,
  error: null,
  inProgress: false
};

export type DialogsState = {
  view: SMSView;
  dialogs: SMSDialogListItem[];
  activeDialog: ActiveDialog | null;
  selectedDialogsParticipants: Set<string> | null;
  searchString: string;
  devicesFilter: string[];
  devices: string[];
  error: string | null;
  inProgress: boolean;
};
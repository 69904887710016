import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => {
  return {
    appBar: {
      position:'fixed'
    },
    toolBarIcon: {
      display: { xs: 'none', md: 'flex' },
      mr: 1
    },
    appBarHeader: {
      mr: 2,
      display: { xs: 'none', md: 'flex' },
      fontFamily: 'monospace',
      fontWeight: 700,
      letterSpacing: '.3rem',
      color: 'inherit',
      textDecoration: 'none',
    },
    appBarBottomSpacer: {
      height: 48
    },
    appBarFiller: {
      flexGrow: 1
    }
  }
});
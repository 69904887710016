// import { Reducer } from "react";
import type { Reducer } from "@reduxjs/toolkit";
import { DialogsAction } from "../actions";
import { INIT_STATE, DialogsState } from ".";

export const dialogsSelectReducer: Reducer<DialogsState, DialogsAction> = (state, action): DialogsState => {
  switch (action.type) {
    case 'DIALOGS_LIST_SELECT_ENABLE': {
      return {...state!, selectedDialogsParticipants: new Set()}
    }

    case 'DIALOGS_LIST_SELECT_DISABLE': {
      return {...state!, selectedDialogsParticipants: null};
    }

    case 'DIALOGS_LIST_SELECT_ADD': {
      if (!state!.selectedDialogsParticipants) {
        return {...state!, selectedDialogsParticipants: new Set(action.payload)};
      }

      return {
        ...state!,
        selectedDialogsParticipants:
          new Set([...state!.selectedDialogsParticipants.keys(), action.payload])
      };
    }

    case 'DIALOGS_LIST_SELECT_REMOVE': {
      if (!state!.selectedDialogsParticipants) {
        return state!;
      }

      const newList = new Set([...state!.selectedDialogsParticipants.keys()]);
      newList.delete(action.payload);
      return {...state!, selectedDialogsParticipants: newList};
    }

    default:
      return state || INIT_STATE;
  }
};
import { UA } from 'jssip'

export const EMPTY_CALLS_DATA: CallsData = {
  devices: [],
  view: 'main',
  input: '',
  errors: []
};

export type CallsData = {
  devices: CallsDataDevice[],
  activeDeviceId?: string | undefined,
  view: 'main' | 'devices' | 'device' | 'new-device',
  viewDeviceId?: string | undefined,
  input: string,
  errors: CallsDataError[],
  activeCall?: CallsDataCall | undefined,
};

export type CallsDataDevice = {
  id: string,
  title: string,
  wss: string,
  domain: string,
  login: string,
  password: string,
  isActive: boolean,
  connection?: UA | undefined
};

export type CallsDataError = {
  deviceId?: string,
  error: string
};

export type CallsDataCall = {
  phoneNumber: string,
  status: 'dialing' | 'buzy' | 'on-call' | 'remote-hangdown' | 'error',
  duration: number,
  error: string
};
import React from "react";

export function useRouter (): Route {
  const [ route, setRoute ] = React.useState<Route>(parseLocation());

  React.useEffect(() => {
    let prev = parseLocation();
    const handler = () => {
      const newRoute = parseLocation();
      if (prev.page !== newRoute.page) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }

      prev = newRoute;
      
      setRoute(newRoute);
    };
    
    window.addEventListener('hashchange', handler);
    window.addEventListener('routechange', handler);
    
    return () => {
      window.removeEventListener('hashchange', handler);
      window.removeEventListener('routechange', handler)
    };
  }, [ setRoute ]);

  return route;
}

export type Route = {
  page: string,
  query: Record<string, string | null | undefined>
};

export function parseLocation (): Route {
  const [ page, queryStr ] = decodeURI(window.location.hash.substring(3)).split('?');
  let query = {} as Record<string, string>;
  if (queryStr != null && queryStr.length > 0) {
    for (const part of queryStr.split('&')) {
      const [ name, value ] = part.split('=');
      query[decodeURIComponent(name)] = decodeURIComponent(value || '');
    }
  }

  return { 
    page: page[page.length - 1] === '/' ? page.substring(0, page.length - 1) : page, 
    query 
  }
}
import { ApiSSO } from "@narayana/sso-api";

export function useApi() {
  return APIInstance;
}

export const APIInstance: API = {
  sso: new ApiSSO("/api/sso")
};

export type API = {
  sso: ApiSSO,
};